// i18next-extract-mark-ns-start apple-pay

import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {IndexBackground} from 'components/landings/IndexBackground';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import applepay_monei from 'images/applepay_monei.svg';
import moneiPayFormEn from 'images/monei_pay_form_en.png';
import moneiPayFormEs from 'images/monei_pay_form_es.png';
import React from 'react';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import CardsContainer from 'components/CardsContainer';
import {Card, CardImage, CardWrapper} from 'components/Card';
import site_template from 'images/site_template.svg';
import coinstack from 'images/coinstack.svg';
import cart from 'images/cart.svg';
import click from 'images/click1.svg';
import mobile_payment from 'images/mobile_payment.svg';
import {InternalPageLink} from 'components/links/Pages';
import person from 'images/person.svg';
import {List} from 'components/List';
import {PrismicPage} from 'components/links/PrismicPage';
import Bold from 'components/Bold';
import {AndroidDownloadLink} from 'components/DownloadOnGooglePlay';
import {IosDownloadLink} from 'components/DownloadOnAppleStore';
import {Partners} from 'components/Partners';
import {FaqsSection} from 'components/FaqsSection';

const ApplePay: React.FC = () => {
  const {t, language} = useI18next();
  const moneiPayForm = ['es', 'ca'].includes(language) ? moneiPayFormEs : moneiPayFormEn;

  const faqs = [
    {
      header: t('How do I activate Apple Pay?'),
      text: t(
        'As soon as your account has been approved, Apple Pay will be enabled by default. You can go to MONEI Dashboard to make adjustments to your configured payment methods.'
      ),
      content: (
        <Trans>
          As soon as your account has been approved, Apple Pay will be enabled by default. You can
          go to{' '}
          <AnchorLink href="https://dashboard.monei.com/?action=signIn">MONEI Dashboard</AnchorLink>{' '}
          to make adjustments to your configured payment methods.
        </Trans>
      )
    },
    {
      header: t('What are the transaction fees for Apple Pay?'),
      text: t(
        'There are no extra costs to accept Apple Pay through MONEI. You only have to pay the regular dynamic credit card fees.'
      ),
      content: (
        <Trans>
          There are no extra costs to accept Apple Pay through MONEI. You only have to pay the
          regular{' '}
          <AnchorLink href="https://support.monei.com/hc/en-us/articles/360017954318-Tarifas-de-MONEI">
            dynamic credit card fees.
          </AnchorLink>{' '}
        </Trans>
      )
    },
    {
      header: t('How does Apple Pay work for customers?'),
      text: t(
        'Our technology automatically detects whether your customer is paying from an iOS device such as a Macbook or iPhone with Touch ID that is using Safari. The Apple Pay button is only shown as a payment method to shoppers paying from an Apple Pay enabled device.  After they select Apple Pay as the payment method, the payment is completed with the Touch ID or Face ID, creating a seamless experience for the customer.'
      ),
      content: (
        <Trans>
          Our technology automatically detects whether your customer is paying from an iOS device
          such as a Macbook or iPhone with Touch ID that is using Safari. The Apple Pay button is
          only shown as a payment method to shoppers paying from an Apple Pay enabled device. After
          they select Apple Pay as the payment method, the payment is completed with the Touch ID or
          Face ID, creating a seamless experience for the customer.
        </Trans>
      )
    },
    {
      header: t('Where is Apple Pay available?'),
      text: t(
        'Today, Apple Pay is available in more than 35 countries. Here is an overview of the countries and regions that support Apple Pay. As more countries support Apple Pay, they are added to this list.'
      ),
      content: (
        <Trans>
          Today, Apple Pay is available in more than 35 countries. Here is an overview of the
          countries and regions that support Apple Pay. As more countries support Apple Pay, they
          are added to{' '}
          <AnchorLink href="https://support.apple.com/en-us/102775">this list.</AnchorLink>
        </Trans>
      )
    }
  ];

  return (
    <>
      <SEO
        path="apple-pay"
        title={t('Accept Apple Pay Payments Online & In-Person')}
        description={t(
          'Increase sales and customer satisfaction by accepting Apple Pay online, in-store, at your restaurant, or anywhere your business takes you. Start here ››'
        )}
      />
      <IndexBackground
        sx={{
          left: {all: '108%', xl: '119%', lg: '1325px'},
          top: {all: '-100%', xl: '-108%', lg: '-125%'},
          height: {all: '2150px', lg: '1960px'},
          width: {all: '1530px'},
          transform: {
            all: 'rotate(-58deg) skew(0deg, 17deg)',
            xl: 'rotate(-58deg) skew(2deg,33deg)',
            lg: 'rotate(-58deg) skew(12deg,12deg)'
          }
        }}>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent>
              <SectionHeader underline tagName="h1">
                <Trans>Accept Apple Pay payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Apple Pay has 500 million users worldwide, and it’s growing rapidly. Accepting Apple
                Pay boosts customer satisfaction and sales thanks to the frictionless and secure
                online and in-person checkout experience. Get Apple Pay to let iOS users quickly and
                easily pay for your products or services.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={applepay_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>

      <Content>
        <Section centered textAlign="center" column>
          <SectionHeader>
            <Trans>
              Benefits of accepting Apple Pay payments online, at your brick-and-mortar store,
              restaurant, or on the go with MONEI
            </Trans>
          </SectionHeader>
          <CardsContainer style={{marginTop: '60px'}}>
            <Card>
              <CardImage src={site_template} alt="" style={{top: -55, width: '100px'}} />
              <CardWrapper>
                <Trans>
                  <AnchorLink href="https://docs.monei.com/docs/payment-methods/apple-pay/">
                    Integrate
                  </AnchorLink>{' '}
                  with your e-commerce store at no extra cost
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={coinstack} alt="" style={{top: -47, width: '100px'}} />
              <CardWrapper>
                <Trans parent="p">Reach more people and increase sales</Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={cart} alt="" style={{width: '90px', top: -65}} />
              <CardWrapper>
                <Trans parent="p">
                  Minimize abandoned online carts with a shorter payment flow
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={click} alt="" style={{width: '100px', top: -40}} />
              <CardWrapper>
                <Trans>Build trust and brand awareness — customize your payment page</Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={mobile_payment} alt="" style={{width: '130px', top: -50}} />
              <CardWrapper>
                <Trans parent="p">
                  Save money on bulky POS devices —{' '}
                  <InternalPageLink slug="monei-pay">
                    take payments from your phone
                  </InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={person} alt="" style={{width: '100px', top: -65}} />
              <CardWrapper>
                <Trans parent="p">
                  Avoid customer queues with faster, contactless in-store payments
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
          <SectionActions>
            <SignUpButton variant="dark" style={{marginRight: 15}}>
              <Trans>Discover MONEI</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section centered>
            <SectionImage
              src={moneiPayForm}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              height={650}
            />
            <div>
              <SectionHeader>
                <Trans>How does Apple Pay work for customers?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Apple Pay lets shoppers store their credit and debit card information in the app so
                they can quickly complete purchases online and off, without ever sharing personal
                details with merchants.
              </Trans>
              <Trans parent="p">
                A faster payment experience leads to increased customer satisfaction and more sales.
                Once Apple Pay users add payment details to the app, it’s available for all future
                purchases.
              </Trans>

              <Trans parent="p">
                <Bold>How Apple Pay online payments work:</Bold>
              </Trans>
              <List>
                <Trans parent="li">
                  MONEI automatically detects if the customer is an iOS user and shows the Apple Pay
                  button on the payment page
                </Trans>
                <Trans parent="li">Customer clicks the Apple Pay button at checkout</Trans>
                <Trans parent="li">
                  Customer selects a stored payment method or enters a new one
                </Trans>
                <Trans parent="li">Customer clicks submit order</Trans>
              </List>

              <Trans parent="p">
                <Bold>How Apple Pay physical payments work:</Bold>
              </Trans>
              <List>
                <Trans parent="li">Customer opens the Apple Pay app on their smartphone</Trans>
                <Trans parent="li">
                  Customer waves their smartphone over an NFC-enabled device or scans a{' '}
                  <InternalPageLink slug="monei-pay/qr">digital QR code</InternalPageLink>
                </Trans>
                <Trans parent="li">Customer authorizes the payment</Trans>
              </List>

              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>

      <Content>
        <Section centered column style={{maxWidth: '940px', margin: 'auto', textAlign: 'center'}}>
          <SectionHeader underline>
            <Trans>Take Apple Pay payments at your business with MONEI</Trans>
          </SectionHeader>
          <Trans parent="p">
            Whether you have multiple{' '}
            <InternalPageLink slug="monei-pay/retail-POS-system">retail locations</InternalPageLink>
            , an{' '}
            <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
              online shop
            </PrismicPage>
            , a{' '}
            <InternalPageLink slug="monei-pay/qr-code-payment-restaurant">
              restaurant
            </InternalPageLink>
            , or need to collect payments on the go for{' '}
            <InternalPageLink slug="monei-pay/pos-for-freelancers">your services</InternalPageLink>,
            MONEI is here to help.
          </Trans>
          <br />
          <Trans parent="p">
            <Bold>Accept Apple Pay online</Bold>
          </Trans>
          <Trans parent="p">
            <AnchorLink href="https://docs.monei.com/docs/payment-methods/apple-pay/">
              Add Apple Pay
            </AnchorLink>{' '}
            to all major e-commerce platforms with a simple plugin, or integrate with your
            custom-built website using our{' '}
            <AnchorLink href="https://docs.monei.com/api/#tag/Payments">Payments API</AnchorLink>.
          </Trans>
          <br />
          <Trans parent="p">
            <Bold>Accept physical payments with Apple Pay</Bold>
          </Trans>
          <Trans parent="p">
            Download the <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> app (
            <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
            <IosDownloadLink>iOS</IosDownloadLink>) to take Apple Pay payments from your phone and
            view online and physical transaction history from anywhere.
          </Trans>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
        </Section>
        <Partners />
      </Content>
      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Apple Pay FAQs</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default ApplePay;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "apple-pay"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
